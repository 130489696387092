import React, { useState } from "react";
import { useStaticQuery, graphql, PageProps, navigate } from "gatsby";
import styled from "styled-components";
import {
  colors,
  fontSizes,
  fontWeights,
  MOBILE_BREAKPOINT,
  IPAD_BREAKPOINT,
  TABLET_BREAKPOINT,
  colorsRGB,
} from "@util/constants";
import { Container, P } from "@util/standard";
import { Accordion, BlocksContent } from "@global";
import { Maybe, Query } from "@graphql-types";
import Hero from "@shared/hero";
import Layout from "@shared/layout";
import SEO from "@shared/seo";

const Wrapper = styled(Container)`
  width: 100%;
  background-color: ${colors.white};
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`;

const TextStyledContainer = styled.div`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    text-align: center;
    min-width: max-content;
  }
`;
const ContentContainer = styled(Container)`
  width: 75%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin: 100px auto 100px auto;
  line-height: 12px;

  .back-button {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    margin: 60px auto 100px auto;
  }
`;

const Text = styled(P)<{ isSelected?: boolean }>`
  margin: 0;
  font-size: ${fontSizes.button.default}px;
  font-weight: ${fontWeights.bold};
  :hover {
    cursor: pointer;
  }
  background-color: ${props =>
    props.isSelected ? colors.green : colors.transparent};
  color: ${props => (props.isSelected ? colors.white : colors.green)};
  margin: 0 20px 40px 0;
  padding: 14px 44px;

  border: 1px solid ${colors.green};
  border-radius: 36px;
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    font-size: ${fontSizes.button.mobile}px;
    padding: 11px 25px;
    margin: 0 10px 30px 0;
  }
`;

const BlocksContentContainer = styled(Container)`
  line-height: 24px;
  margin-top: -20px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      font-size: ${fontSizes.p.mobile}px;
      line-height: 16.8px;
    }
  }
`;

const SectionTitleContainer = styled(Container)`
  flex-direction: row;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${colors.white};
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${colorsRGB.merlin(0.4)};
  }
`;

const SectionContentContainer = styled(Container)`
  flex-direction: column;
  width: 95%;
`;

const DesktopSectionContent = styled(Container)`
  width: 100%;
`;

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}
const FAQPage = ({ pageContext, data }: Props) => {
  const sanityFaqPage = data.allSanityFaqPage.nodes[0];

  if (sanityFaqPage == null) {
    return null;
  }

  const sectionTitleKeys = sanityFaqPage?.faqContent?.map(term => {
    if (term == null) {
      return;
    }
    return { title: term.title, key: term._key, sectionContent: term.sections };
  });

  const [dataFilter, setDataFilter] = useState(
    sectionTitleKeys[0]?.key as string
  );

  const handleClick = (key: Maybe<string> | undefined) => {
    if (key) {
      setDataFilter(key);
    }
  };

  const SectionContent = () => {
    return (
      <SectionContentContainer>
        {sectionTitleKeys?.map(item => {
          if (item == null) {
            return null;
          }
          if (item.key === dataFilter) {
            return (
              <Container flexDirection="column" key={item.key}>
                {item?.sectionContent?.map(section => {
                  if (section == null) {
                    return null;
                  }
                  return (
                    <Container key={section._key}>
                      {sectionTitleKeys?.map(item => {
                        if (item == null) {
                          return null;
                        }
                        if (item.key === dataFilter) {
                          return (
                            <Accordion
                              key={section._key}
                              title={section.title as string}
                              padding="10px 0 0 0"
                              margin="0 0 10px 0"
                              normal
                            >
                              <BlocksContentContainer key={item.key}>
                                <BlocksContent
                                  blocks={section.sectionContent?._rawBlocks}
                                />
                              </BlocksContentContainer>
                            </Accordion>
                          );
                        }
                        return null;
                      })}
                    </Container>
                  );
                })}
              </Container>
            );
          }
          return null;
        })}
      </SectionContentContainer>
    );
  };

  return (
    <>
      <SEO seoData={sanityFaqPage?.seo} />
      <Layout>
        <Hero sanityHero={sanityFaqPage?.hero} />
        <Wrapper>
          <ContentContainer>
            <a className="back-button" onClick={() => navigate(-1)}>
              &lt; Back
            </a>
            <SectionTitleContainer>
              {sectionTitleKeys?.map(item => {
                if (item == null) {
                  return null;
                }
                return (
                  <TextStyledContainer key={item.key}>
                    <Text
                      key={item.key}
                      isSelected={dataFilter === item.key}
                      onClick={() => handleClick(item.key)}
                    >
                      {item?.title}
                    </Text>
                  </TextStyledContainer>
                );
              })}
            </SectionTitleContainer>
            <DesktopSectionContent>
              <SectionContent />
            </DesktopSectionContent>
          </ContentContainer>
        </Wrapper>
      </Layout>
    </>
  );
};

export default FAQPage;

export const query = graphql`
  query allFaqQuery($iban: String) {
    allSanityFaqPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        _key
        _id
        faqContent {
          _key
          title
          sections {
            _key
            title
            sectionContent {
              _rawBlocks
            }
          }
        }
        region {
          iban
        }
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
      }
    }
  }
`;
